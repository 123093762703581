<template>
  <div class="content">
    <a-row class="top" justify="center" align="middle" type="flex">
        <span>公告栏</span>
    </a-row>

    <div class="container">
        <a-row :gutter="90" type="flex">
            <a-col :span="12" class="hover mb60"  v-for="item in list" :key="item.title" @click="$router.push(item.router)">
                <div class="date fl">
                    <div class="align" v-if="!item.date">
                        <p>05</p>
                        <div>2021/12</div>
                    </div>
                    <div class="align" v-else>
                        <p>{{item.dateDay}}</p>
                        <div>{{item.date}}</div>
                    </div>
                </div>
                <div class="info">
                    <p class="title">{{item.title}}</p>
                    <p class="text">{{item.text}}</p>
                </div>
            </a-col>
        </a-row>
    </div>
  </div>
</template>

<script>
export default {
       data(){
           return{
               list:[
                   {title:'关于明确增值税小规模纳税人减免增值税等政策的公告',text:'一、自2023年1月1日至2023年12月31日,对月销售额10万元以下（含本数）的增值税小规模纳税人，免征增值税。...', router: '/notice-details/stop',date:'2023/01',dateDay:'01'},
                   {title:'91再生交易平台上线了',text:'针对再生资源行业合规乱象问题，91再生通过多年的交易经验并与相关部门合作，提供再生资源合规解决方案，通过...', router: '/notice-details/s1'},
                   {title:'91再生交易平台执行“实名认证”入驻',text:'为确保交易自然人的知悉权，避免身份被他人借用的情况，平台卖家入驻时需...', router: '/notice-details/s3'},
                   {title:'关于个体工商户发票存证限额的通知',text:'根据《国家税务总局关于统一小规模纳税人标准等若干增值税问题的公告》（国家税务总局公告2018年第18号）规定：...', router: '/notice-details/s4'},
                   {title:'91再生网依法进行主体登记，履行纳税义务',text:'依据《中华人民共和国电子商务法》, 91再生交易平台依法进行主体登记，履行纳税义务。根据第二章第十条：“电子商务经营者应当依法办理市场主体登记...', router: '/notice-details/s5'},
                   {title:'小规模增值税1%征收率将于2021年12月31结束',text:'小规模增值税1%征收率将于2021年12月31结束，2022年1月1日起按3%征收；季度45万的免征增值税政策到期时间为2022年12月...', router: '/notice-details/s2'},
               ]
           }
       }
}
</script>

<style lang="less" scoped>
.content {
    max-width: 1440px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
        padding: 0 15px;
    }
    margin: 0 auto;
    margin-bottom: 62px;
    .top {
        background-color: rgba(113, 180, 52, 1);
        height: 300px;
        width: 100%;
        color: #fff;
        font-size: 48px;
        font-weight: 700;
    }
    .container {
        padding: 60px 50px;
        background-color: #fff;
        box-shadow: 1px 1px 2px rgb(170 170 170 / 35%);
        line-height: 36px;
        .date {
            width: 80px;
            height: 80px;
            border: 1px solid #71b434;
            color: #71b434;
            text-align: center;
            position: relative;
            .align {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 24px;
            }
            p {
                font-weight: 700;
                font-size: 28px;
                line-height: 24px;
            }
        }
        .info {
            margin-left: 100px;
            .title {
                font-weight: 700;
                font-size: 18px;
                transition: all .1s;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: rgba(234, 164, 0, 1);
                }
            }
            .text {
                color: #AAAAAA;
                line-height: 24px;
                 text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .hover:hover {
            cursor: pointer;
            .date {
                background-color: rgba(113, 180, 52, 1);
                color: #fff;
            }
        } 
    }
}
.mb60{
    margin-bottom: 60px;
}
</style>